<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref, watch } from 'vue'
import { getOrders, restartAutomaticCancelation } from './api'
import AppPagination from '@/components/app-pagination'
import Notify from '@/notify'
import SelectContest from '@/components/select-contest'
import SelectCustomer from '@/components/select-customer'
import debounce from '@/utils/debounce'

const statusMap = {
  CONFIRMED: {
    label: 'Confirmado',
    class: 'badge-soft-success'
  },
  CANCELED: {
    label: 'Cancelado',
    class: 'badge-soft-danger'
  },
  PROCESSING: {
    label: 'Processando',
    class: 'badge-soft-warning'
  },
  PENDING: {
    label: 'Pendente',
    class: 'badge-soft-primary'
  }
}

export default {
  setup () {
    const ordersList = ref([])
    const page = ref(1)
    const pages = ref(0)
    const total = ref(0)
    const filter = ref({
      contest_id: null,
      user_id: null,
      status: null,
      created_at: {
        from: null,
        to: null
      },
      confirmed_at: {
        from: null,
        to: null
      }
    })

    onMounted(() => {
      fetchOrders()
    })

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    const fetchOrders = debounce(() => {
      const params = {
        page: page.value,
        contest_id: filter.value?.contest_id?.value,
        user_id: filter.value?.user_id?.value,
        status: filter.value.status,
        created_at: {
          gte: dateToISO(filter.value.created_at.from),
          lte: dateToISO(filter.value.created_at.to)
        },
        confirmed_at: {
          gte: dateToISO(filter.value.confirmed_at.from),
          lte: dateToISO(filter.value.confirmed_at.to)
        },
        order: {
          created_at: 'desc'
        }
      }

      getOrders(params).then((data) => {
        ordersList.value = data.data
        pages.value = data.last_page
        total.value = data.total
      }).catch(() => {
        Notify.create({
          title: 'Falha ao carregar os pedidos',
          timer: 1500
        })
      })
    }, 1000)

    watch(filter, () => {
      page.value = 1
      fetchOrders()
    }, { deep: true })

    const statusGet = (status, key) => statusMap[status][key]

    const doRestartAutomaticCancelation = () => {
      restartAutomaticCancelation().then(() => {
        Notify.create({
          title: 'Cancelamento automático reiniciado com sucesso',
          timer: 1500
        })
      }).catch(() => {
        Notify.create({
          title: 'Falha ao reiniciar cancelamento automático',
          timer: 1500
        })
      })
    }

    return {
      page,
      pages,
      total,
      ordersList,
      fetchOrders,
      statusGet,
      filter,
      statusMap,
      doRestartAutomaticCancelation
    }
  },
  components: {
    SelectCustomer,
    SelectContest,
    AppPagination,
    Layout
  }
}

</script>

<template>
  <Layout :pagetitle="'Pedidos'">
    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-lg-4 mb-2">
                <select-contest
                  v-model="filter.contest_id"
                  placeholder="Pesquisar por sorteio"
                />
              </div>
              <div class="col-sm-12 col-lg-4 mb-2">
                <select-customer
                  v-model="filter.user_id"
                  placeholder="Pesquisar por cliente"
                />
              </div>
              <div class="col-sm-12 col-lg-4 mb-2">
                <select
                  v-model="filter.status"
                  class="form-select"
                >
                  <option :value="null">
                    Status
                  </option>
                  <option
                    v-for="(status, key) in statusMap"
                    :key="key"
                    :value="key"
                  >
                    {{ status.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.created_at.from"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data de criação(inicial)"
                >
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.created_at.to"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data de criação(final)"
                >
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.confirmed_at.from"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data de confirmação(inicial)"
                >
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.confirmed_at.to"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data de confirmação(final)"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-3">
          <b-button
            variant="primary"
            @click="doRestartAutomaticCancelation()"
          >
            Reiniciar Cancelamento Automático
          </b-button>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-striped mb-4"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      Sorteio
                    </th>
                    <th scope="col">
                      Cliente
                    </th>
                    <th scope="col">
                      Total
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th scope="col">
                      Aberto em
                    </th>
                    <th scope="col">
                      Pago em
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="order in ordersList"
                    :key="order.id"
                  >
                    <td>{{ order.contest? order.contest.title : 'N/I' }}</td>
                    <td>{{ order.client? order.client : 'N/I' }}</td>
                    <td>{{ order.total? $n(order.total, 'currency') : '' }}</td>
                    <td>
                      <span
                        class="badge rounded-pill"
                        :class="statusGet(order.status, 'class')"
                      >
                        {{ statusGet(order.status, 'label') }}
                      </span>
                    </td>
                    <td>{{ order.created_at? $d(order.created_at, 'info') : '' }}</td>
                    <td>{{ order.confirmed_at? $d(order.confirmed_at, 'info') : '' }}</td>
                  </tr>
                </tbody>
              </table>
              <app-pagination
                v-model="page"
                class="pagination"
                :pages="pages"
                :range-size="1"
                @update:modelValue="fetchOrders"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
